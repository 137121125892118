// api.js
export const apiFetch = async (url, options = {}) => {
    const baseURL = '/api';
    const fetchOptions = {
      ...options,
      credentials: 'include', // Send cookies with every request
      headers: {
        'Content-Type': 'application/json',
        ...(options.headers || {}),
      },
    };
  
    const fetchRequest = async () => {
      const response = await fetch(`${baseURL}${url}`, fetchOptions);
  
      if (!response.ok) {
        if (response.status === 401 && !fetchOptions._retry) {
          fetchOptions._retry = true;
  
          try {
            const refreshResponse = await fetch(`${baseURL}/refresh-token`, {
              method: 'POST',
              credentials: 'include',
            });
  
            if (refreshResponse.ok) {
              const { token } = await refreshResponse.json();
  
              // Update Authorization header and retry the original request
              fetchOptions.headers['Authorization'] = `Bearer ${token}`;
              return fetchRequest();
            } else {
              throw new Error('Session expired. Please log in again.');
            }
          } catch (error) {
            throw error;
          }
        }
      }
  
      return response;
    };
  
    return fetchRequest();
  };
  