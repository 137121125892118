import React from 'react';
import './FeatureSection.css';
import { MdInsights, MdAssistant, MdOutlinePsychology, MdTrendingUp } from 'react-icons/md'; // Updated icons

const FeatureSection = () => {
  return (
    <section className="feature-section">
      <div className="feature-content">
        <h2>
          Unlock peak productivity and optimal health with{' '}
          <span className="highlight">AI-powered insights</span> and advanced neurofeedback from WhiteMatter.
        </h2>
      </div>
      <div className="feature-links">
        <div className="feature-item1">
          <MdInsights size={40} className="icon" />
          <span>Real-Time Insights</span>
        </div>
        <div className="feature-item1">
          <MdAssistant size={40} className="icon" />
          <span>Personalized Feedback</span>
        </div>
        <div className="feature-item1">
          <MdOutlinePsychology size={40} className="icon" />
          <span>Cognitive Well-Being</span>
        </div>
        <div className="feature-item1">
          <MdTrendingUp size={40} className="icon" />
          <span>Enhance Productivity</span>
        </div>
      </div>
    </section>
  );
};

export default FeatureSection;
