import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import for redirection
import './Signup.css'; // Import the CSS file
import WhiteMatter from './White Matter - 440x280 px - original dark grey on transparent.png';
import { BiArrowBack } from 'react-icons/bi';

const Signup = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    workEmail: '',
    jobTitle: '',
    department: '',
    type: '',
    password: '',
    confirmPassword: '',  // Add confirmPassword field
    termsAccepted: false,
    registrationCode: '',  // Add registrationCode field for business users
  });
  const [departments, setDepartments] = useState([]); // Store departments
  const [error, setError] = useState(''); // Add error state
  const navigate = useNavigate(); // Use navigate for redirection
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Fetch department names from the server when the component mounts
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await fetch('/api/departments');
        const data = await response.json();
        setDepartments(data); // Set the department names
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    };

    fetchDepartments();
  }, []);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  
    // Password and confirm password validation
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }
  
    if (!formData.termsAccepted) {
      alert('Please accept the terms.');
      return;
    }
  
    try {
      const response = await fetch('/api/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),  // Ensure registrationCode is included in the formData
      });
  
      const data = await response.json();
  
      if (response.status === 400) {
        setError(data.message); // Show error message
      } else if (response.status === 200) {
        // If OTP is sent, redirect to OTP verification page with the email
        navigate('/otp', { state: { email: formData.workEmail } });
      }
    } catch (err) {
      console.error('Error:', err);
    }
    finally {
      setIsSubmitting(false); // Re-enable the button after processing
    }
  };
  const handleBackClick = () => {
   
    navigate('/'); 
  };

  return (
    <div className="container-signup">
      <div className="card3">
        <div className="form-section3">
        <button className="back-button-signup" onClick={handleBackClick} >
      <BiArrowBack size={24} /> 
        </button>
          <h1 className="title">Join <span className="brand-name">Erasys</span></h1>
          <p className="subtitle">Unlock your 60-day free trial. No credit card needed.</p>
          {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error message */}
          <form onSubmit={handleSubmit}>
            {/* All input fields here */}
            <div className="input-wrapper">
              <input className='signup'
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder="First Name"
                required
              />
            </div>
            <div className="input-wrapper">
              <input className='signup'
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Last Name"
                required
              />
            </div>
            <div className="input-wrapper">
              <input className='signup'
                type="email"
                name="workEmail"
                value={formData.workEmail}
                onChange={handleChange}
                placeholder="Work Email"
                required
              />
            </div>
            <div className="input-wrapper">
              <input className='signup'
                type="text"
                name="jobTitle"
                value={formData.jobTitle}
                onChange={handleChange}
                placeholder="Job Title"
                required
              />
            </div>
            <div className="input-wrapper">
              <select
                name="department"
                value={formData.department}
                onChange={handleChange}
                required
              >
                <option value="">Select Department</option>
                {departments.map((dept, index) => (
                  <option key={index} value={dept}>
                    {dept}
                  </option>
                ))}
              </select>
            </div>
            <div className="input-wrapper">
              <select
                name="type"
                value={formData.type}
                onChange={handleChange}
                required
              >
                <option value="">Type</option>
                <option value="B2C">Personal</option>
                <option value="B2B">Business</option>
              </select>
            </div>

            {/* Conditionally render the Registration Code field */}
            {formData.type === 'B2B' && (
              <div className="input-wrapper">
                <input className='signup'
                  type="text"
                  name="registrationCode"
                  value={formData.registrationCode}
                  onChange={handleChange}
                  placeholder="Registration Code"
                  required
                />
              </div>
            )}

            <div className="input-wrapper">
              <input className='signup'
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
                required
              />
            </div>
            <div className="input-wrapper">
              <input className='signup'
                type="password"
                name="confirmPassword"  // Add confirmPassword input
                value={formData.confirmPassword}
                onChange={handleChange}
                placeholder="Confirm Password"
                required
              />
            </div>
            <div className="checkbox-wrapper">
              <input 
                className="checkbox"
                type="checkbox"
                name="termsAccepted"
                checked={formData.termsAccepted}
                onChange={handleChange}
              />
              <label className="checkbox-label">
                I agree to the <a href="/privacy">Terms of Service</a>.
              </label>
            </div>
            <button className="button" type="submit" disabled={isSubmitting}>{isSubmitting ? 'Get Started...' : 'Get Started'}</button>
          </form>
          <div className="link-wrapper">
            <p className="small-text">Already a member? <a href="/signin">Login</a></p>
          </div>
        </div>

        <div className="image-section">
          <img className="image" src={WhiteMatter} alt="BuzzSumo Chart" />
        </div>
      </div>
    </div>
  );
};

export default Signup;
