import React from 'react';
import { useNavigate } from 'react-router-dom';
import './FeatureFamily.css'; 
import productivityImg from './output (1).png';
import stressImg from './file (3).png';
import neurofeedbackImg from './cognitiveAi.png';

const FeatureFamily = () => {
  const navigate = useNavigate(); // useNavigate hook for programmatic navigation

  // Handle click for Learn More to navigate to the Products page and scroll to "Work Smarter"
  const handleLearnMoreClick = () => {
    navigate('/products', { state: { scrollToWorkSmarter: true } }); // Pass state to scroll to "Work Smarter"
  };
  return (
    <section className="feature-family">
      <h2>Meet the Whitematter Features</h2>

      <div className="feature-grid">
        <div className="feature-item feature-large" onClick={handleLearnMoreClick}>
          
            <img src={productivityImg} alt="Productivity Insights" />
            <h3 className='productivity'>Productivity Insights</h3>
            <p>Boost your productivity with real-time metrics that guide you towards achieving more every day.</p>
         
        </div>

        <div className="right-side">
          <div className="feature-item" onClick={handleLearnMoreClick}>
            
              <img className='stressimage' src={stressImg} alt="Stress Management" />
              <h3>Stress Management</h3>
              <p>Monitor stress levels and cognitive fatigue, and get actionable feedback to reduce burnout.</p>
           
          </div>

          <div className="feature-item" onClick={handleLearnMoreClick}>
            
              <img src={neurofeedbackImg} alt="Neurofeedback" />
              <h3>Neurofeedback</h3>
              <p>Experience AI-powered neurofeedback that helps you form healthier, more productive work habits.</p>
            
          </div>
        </div>
      </div>
    </section>
  );
};







//adjust the video so the user shouldnt have to scroll in order to see the full content.
//all black bold changed to blue in first section.
//adjust the family section to look like microsoft surface pro 
//learn more links to products page, try for free links to the download section in the products page 
//in family section the images should be clickable and redirect the user to the products page 
// make the work smarter section auto

export default FeatureFamily;
