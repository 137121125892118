import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import './Account.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons'; // Import icons
import { faApple, faWindows } from '@fortawesome/free-brands-svg-icons'; // Brand Icons


const Account = () => {
  const [activeTab, setActiveTab] = useState('subscription');
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
  });
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Initialize useNavigate for navigation

  // State for handling modals and reset process
  const [showCodeModal, setShowCodeModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [resetCode, setResetCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // Add error and message state to handle responses
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (activeTab === 'profile') {
      const fetchProfileData = async () => {
        try {
          const response = await fetch('/api/profile', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });

          if (response.ok) {
            const data = await response.json();
            setUserData(data);
            setLoading(false);
          } else {
            setError('Error fetching profile data.');
            setLoading(false);
          }
        } catch (error) {
          setError('Error fetching profile data.');
          setLoading(false);
        }
      };

      fetchProfileData();
    } else if (activeTab === 'subscription') {
      const fetchSubscriptionData = async () => {
        try {
          const response = await fetch('/api/subscription', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });

          if (response.ok) {
            const data = await response.json();
            setSubscriptionData(data);
            setLoading(false);
          } else {
            setError('Error fetching subscription data.');
            setLoading(false);
          }
        } catch (error) {
          setError('Error fetching subscription data.');
          setLoading(false);
        }
      };

      fetchSubscriptionData();
    }
  }, [activeTab]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setLoading(true); // Set loading to true when switching tabs
    setError(''); // Clear any previous errors
    setMessage(''); // Clear any previous messages
  };

  const handleSaveChanges = async () => {
    setIsSaving(true);
    try {
      const response = await fetch('/api/profile', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          firstName: userData.firstName,
          lastName: userData.lastName,
        }),
      });

      if (response.ok) {
        setMessage('Profile updated successfully.');
        setError('');
      } else {
        setError('Failed to update profile.');
        setMessage('');
      }
    } catch (error) {
      setError('An error occurred while updating your profile.');
      setMessage('');
    }finally {
      setIsSaving(false)
    }
  };

  // Redirect to pricing page
  const handleRedirectToPricing = () => {
    navigate('/pricing');
  };

  // Handle reset password process
  const handleResetPassword = async () => {
    setIsSubmitting(true)
    try {
      const response = await fetch('/api/reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: userData.email }),
      });

      if (response.ok) {
        setShowCodeModal(true); // Show modal to enter reset code
        setMessage('Reset code sent to your email.');
        setError('');
      } else {
        setError('Failed to send reset code.');
        setMessage('');
      }
    } catch (error) {
      setError('Error sending reset password request.');
      setMessage('');
    }finally{
      setIsSubmitting(false)
    }
  };

  const handleVerifyCode = async () => {
    setIsSubmitting(true)
    try {
      const response = await fetch('/api/verify-reset-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: userData.email, resetCode }),
      });

      if (response.ok) {
        setShowCodeModal(false); // Close code modal
        setShowPasswordModal(true); // Show new password modal
        setError(''); // Clear any previous error
        setMessage('');
      } else {
        setError('Invalid reset code.');
        setMessage('');
      }
    } catch (error) {
      setError('Error verifying reset code.');
      setMessage('');
    }finally{
      setIsSubmitting(false)
    }
  };

  const handleSubmitNewPassword = async () => {
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      setMessage('');
      return;
    }
    setIsSubmitting(true)

    try {
      const response = await fetch('/api/reset-password-complete', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: userData.email,
          newPassword,
        }),
      });

      if (response.ok) {
        setShowPasswordModal(false); // Close modal
        setMessage('Password reset successful.');
        setError('');
      } else {
        setError('Failed to reset password.');
        setMessage('');
      }
    } catch (error) {
      setError('Failed to reset password.');
      setMessage('');
    }finally{
      setIsSubmitting(false)
    }
  };

  // Function to map skuId to subscription name
  const getSubscriptionName = (skuId) => {
    const mapping = {
      'STD_Mac01': 'WhiteMatter Standard MacOS',
      'STD_Win01': 'WhiteMatter Standard Windows',
      'Free_Mac01': 'WhiteMatter Free Trial MacOS',
      'Free_Win01': 'WhiteMatter Free Trial Windows',
      // Add more mappings as needed
    };
    return mapping[skuId] || 'Unknown Subscription';
  };

  // Function to get plan type from skuId
  const getPlanType = (skuId) => {
    if (skuId && skuId.startsWith('STD')) {
      return 'Standard';
    } else if (skuId && skuId.startsWith('Free')) {
      return 'Free Trial';
    } else {
      return 'Unknown Plan';
    }
  };

  const handleDownloadOrPricingRedirect = (platform) => {
    if (!subscriptionData) {
      navigate('/pricing');
    } else {
      // Logic to download the file for MacOS, Windows, or trial (based on the platform argument)
      const fileUrl = platform === 'macos'
        ? 'https://whitematterapp.blob.core.windows.net/installers/WhiteMatter-MacOS.zip'
        : platform === 'windows'
        ? 'https://whitematterapp.blob.core.windows.net/installers/WhiteMatter.zip'
        : 'https://whitematterapp.blob.core.windows.net/installers/WhiteMatter.zip';

      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', '');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="account-container">
      <div className="sidebar-1">
        <ul>
          <li
            className={activeTab === 'profile' ? 'active' : ''}
            onClick={() => handleTabClick('profile')}
          >
            Profile
          </li>
          <li
            className={activeTab === 'subscription' ? 'active' : ''}
            onClick={() => handleTabClick('subscription')}
          >
            Subscription
          </li>
        </ul>
      </div>

      <div className="account-details">
        {activeTab === 'subscription' ? (
          <div className="section profile">
            <h2>Subscription Details</h2>
            {loading ? (
              <div>Loading...</div>
            ) : error ? (
              <div className="error-message">{error}</div>
            ) : !subscriptionData ? (
              <div>
                <p>You don't have an active subscription.</p>
                <button className="button" onClick={handleRedirectToPricing}>
                  View Pricing Plans
                </button>
              </div>
            ) : (
              subscriptionData && (
                <>
                  <div className="profile-field">
                    <label>Subscription Name:</label>
                    <input type="text" value={getSubscriptionName(subscriptionData.skuId)} disabled />
                  </div>
                  <div className="profile-field">
                    <label>Plan Type:</label>
                    <input
                      type="text"
                      value={`${getPlanType(subscriptionData.skuId)}: valid till ${new Date(
                        subscriptionData.subscriptionEndTime
                      ).toLocaleDateString()}`}
                      disabled
                    />
                  </div>
                  <div className="profile-field">
                    <label>Registration Key:</label>
                    <input
                      type="text"
                      value={subscriptionData.subscriptionId}
                      className="registration-key"
                      disabled
                    />
                  </div>
                </>
              )
            )}
            <div className="platform-buttons">
  <p className="download-now-text">Download Now</p>
  <div className="platform-button-wrapper">
    <div className="platform-button-container">
      <button className="platform-button macos-button"onClick={() => handleDownloadOrPricingRedirect('macos')}>
        <FontAwesomeIcon icon={faApple} size="2x" />
      </button>
      <p className="platform-text">MacOS</p>
    </div>
    <div className="platform-button-container">
      <button className="platform-button windows-button"onClick={() => handleDownloadOrPricingRedirect('windows')}>
        <FontAwesomeIcon icon={faWindows} size="2x" />
      </button>
      <p className="platform-text">Windows</p>
    </div>
  </div>
</div>

          </div>
        ) : (
          <div className="section profile">
            <h2>Profile</h2>
            {loading ? (
              <div>Loading...</div>
            ) : (
              <>
                {error && <div className="error-message-1">{error}</div>}
                {message && <div className="success-message-1">{message}</div>}
                <div className="profile-field">
                  <label>First Name:</label>
                  <input
                    type="text"
                    name="firstName"
                    value={userData.firstName}
                    onChange={(e) =>
                      setUserData((prev) => ({ ...prev, firstName: e.target.value }))
                    }
                  />
                </div>
                <div className="profile-field">
                  <label>Last Name:</label>
                  <input
                    type="text"
                    name="lastName"
                    value={userData.lastName}
                    onChange={(e) =>
                      setUserData((prev) => ({ ...prev, lastName: e.target.value }))
                    }
                  />
                </div>
                <div className="profile-field">
                  <label>Email:</label>
                  <input type="email" value={userData.email} disabled />
                </div>
                <div className="profile-field">
                  <label>Company:</label>
                  <input type="text" value={userData.company || 'N/A'} disabled />
                </div>
                <div className="button-container">
                  <button className="button save" onClick={handleSaveChanges} disabled={isSaving}>
                  {isSaving ? 'Saving...' : 'Save'}
                  </button>
                  <button className="button reset" onClick={handleResetPassword} disabled={isSubmitting}>
                  {isSubmitting ? 'Reset Password...' : 'Reset Password'}
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </div>

      {/* Modal for entering reset code */}
      {showCodeModal && (
        <div className="modal-account">
          <div className="modal-content-account">
            <h2>Enter Reset Code</h2>
            <p>A code has been sent to your email. Enter it below:</p>
            {error && <div className="error-message-1">{error}</div>}
            {message && <div className="success-message-1">{message}</div>}
            <input
              className="input-reset-code"
              type="text"
              placeholder="Enter code"
              value={resetCode}
              onChange={(e) => setResetCode(e.target.value)}
            />
            <button onClick={handleVerifyCode} className="submit-button" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </div>
      )}

      {/* Modal for entering new password */}
      {showPasswordModal && (
        <div className="modal-account">
          <div className="modal-content-account">
            <h2>Change Your Password</h2>
            <p>Enter a new password below to change your password.</p>
            <div className="message-container">
              {error && <div className="error-message-1">{error}</div>}
              {message && <div className="success-message-1">{message}</div>}
            </div>

            <div className="input-group">
              <label>New Password</label>
              <input
                className="input-new-password"
                type="password"
                placeholder="New password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="input-group">
              <label>Confirm Password</label>
              <input
                className="input-confirm-password"
                type="password"
                placeholder="Confirm password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <button onClick={handleSubmitNewPassword} className="submit-button" disabled={isSubmitting}>
            {isSubmitting ? 'Changing password...' : 'Change password'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Account;
