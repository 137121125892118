import React, { useState } from 'react';
import './OtpVerification.css';
import { useNavigate } from 'react-router-dom';  // Import useNavigate from react-router-dom
import { BiArrowBack } from 'react-icons/bi';

const OtpVerification = () => {
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  
    try {
      const response = await fetch('/api/verify-otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ otp }),
      });
  
      const data = await response.json();
  
      if (response.status === 400) {
        setError(data.message);
      } else if (response.status === 200) {
        localStorage.setItem('token', data.token);  // Store the token for both B2B and B2C users
        setSuccessMessage('Successfully verified! You are now registered.');
  
        // Redirect to the home page after OTP verification
        setTimeout(() => {
          navigate('/');  // Redirect the user to the home page
        }, 2000);
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    }finally {
      setIsSubmitting(false); // Re-enable the button after processing
    }
  };

  const handleBackClick = () => {
    // Remove specific items from session storage
    sessionStorage.removeItem('otpData'); // Example key
  
    // Remove specific items from local storage
    localStorage.removeItem('registrationData'); // Example key
  
    // Navigate back to the signup page
    navigate('/signup');
  };
  
  
  return (
    <div className="container2">
      <div className="card2">
      <button className="back-button" onClick={handleBackClick} >
      <BiArrowBack size={24} /> 
        </button>
        <h1>Verfication Code</h1>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Enter Verfication Code"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            required
          />
          <button type="submit"  disabled={isSubmitting}>{isSubmitting ? 'Verifying...' : 'Verify'}</button>
        </form>
      </div>
    </div>
  );
};

export default OtpVerification;
