import React, { useState, useEffect } from 'react';
import './Header.css';
import Logo from './Erasys-logo.png';
import { FaBars, FaTimes, FaUserCircle } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { apiFetch } from './api'; // Import the API utility

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isProductsMenuOpen, setIsProductsMenuOpen] = useState(false); // State for the products dropdown
  const navigate = useNavigate();

  // Check if the user is logged in by looking for the token in localStorage
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  // Toggle the menu on hamburger click (only for mobile)
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Toggle the submenu for account options
  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  // Handle logout
  const handleLogout = async () => {
    try {
      await apiFetch('/logout', { method: 'POST' });
      localStorage.removeItem('token');
      setIsLoggedIn(false);
      setIsMenuOpen(false); // Close the menu after logout
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  // Toggle the products dropdown
  const toggleProductsMenu = () => {
    setIsProductsMenuOpen(!isProductsMenuOpen);
  };

  return (
    <header className="header">
      {/* Hamburger for mobile view */}
      <div className="hamburger-icon" onClick={toggleMenu}>
        {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
      </div>

      {/* Logo */}
      <div className="logo1">
        <img src={Logo} alt="Erasys Logo" />
      </div>

      {/* Navigation Links */}
      <nav className={`nav ${isMenuOpen ? 'nav-open' : ''}`}>
        <ul>
          <li><Link to="/" onClick={toggleMenu}>Home</Link></li>

          {/* Products dropdown */}
          <li className="products-menu">
            <button onClick={toggleProductsMenu} className="dropdown-toggle">
              Products
            </button>
            {isProductsMenuOpen && (
              <ul className="submenu">
                <li><Link to="/products#whitematter" onClick={toggleMenu}>WhiteMatter</Link></li>
                {/* Add more items to the products dropdown as needed */}
              </ul>
            )}
          </li>

          <li><Link to="/pricing" onClick={toggleMenu}>Pricing</Link></li>
          <li><Link to="/contact" onClick={toggleMenu}>Contact Us</Link></li>

          {/* In mobile view, show Account/Logout */}
          <div className="mobile-auth-links">
            {isLoggedIn ? (
              <>
                <li><Link to="/account" onClick={toggleMenu}>Account</Link></li>
                <li><button className="logout-button" onClick={handleLogout}>Logout</button></li>
              </>
            ) : (
              <>
                <li><Link to="/signin" onClick={toggleMenu}>Sign In</Link></li>
                <li><Link to="/signup" onClick={toggleMenu}>Sign Up</Link></li>
              </>
            )}
          </div>
        </ul>
      </nav>

      {/* Try for Free button and Account Icon */}
      <div className="actions">
        <Link to="/pricing" className="try-button">Try for free</Link>

        {/* Account Icon with Dropdown (Desktop only) */}
        <div className="account-wrapper" onClick={toggleSubMenu}>
          <FaUserCircle size={40} className="account-avatar" />
          {isSubMenuOpen && (
            <div className="account-submenu">
              {isLoggedIn ? (
                <>
                  <Link to="/account" className="account-button">My Profile</Link>
                  <button onClick={handleLogout} className="logout-button">Logout</button>
                </>
              ) : (
                <>
                  <Link to="/signin" className="signin-button">Sign In</Link>
                  <Link to="/signup" className="signup-button">Sign Up</Link>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Overlay for dimming the background when menu is open */}
      {isMenuOpen && <div className="menu-overlay" onClick={toggleMenu}></div>}
    </header>
  );
};

export default Header;
