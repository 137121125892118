import React from 'react';
import './AboutUs.css';  // You will create this CSS file


const AboutUs = () => {
  return (
    <div className="about-us-page">
      <div className="about-hero-section">
        <div className="overlay">
        <h1>Shaping the Future</h1>
          <p>At Erasys, we innovate to empower businesses and improve lives through cutting-edge technology.</p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
