import React, { useRef, useEffect } from 'react';
import './VideoSection.css';


const VideoSection = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    // Set the default volume to full (1 is the max)
    if (videoRef.current) {
      videoRef.current.volume = 1;  // Set volume to full
      videoRef.current.muted = false;  // Ensure video starts unmuted
    }
  }, []);

  return (
    <section className="video-section">
      <div className="video-container">
        <video 
          ref={videoRef} 
          controls  // Ensure controls are enabled
          preload="metadata" 
          loop  // Ensure video loops
          controlsList="nodownload"  // Restrict unwanted controls
        >
          <source src='https://whitematter-video-htdmb7bfgjh4gkb6.z01.azurefd.net/whitemattervideo/whitematter.mp4' type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </section>
  );
};




export default VideoSection; 