import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';  // Import useNavigate, useLocation
import Header from './components/Header';
import VideoSection from './components/VideoSection';
import FeatureSection from './components/FeatureSection';
import FeatureFamily from './components/FeatureFamily';
import Footer from './components/Footer';
import Products from './components/Products';  // Import the Products component
import ContactUs from './components/ContactUs';
import AboutUs from './components/AboutUs';
import PricingPage from './components/PricingPage';
import Signup from './components/Signup';
import Whitematter from '../src/components/whitematter-logo.png';
import Whitematterimage from './components/image (8) (1).png';
import Sign from './components/Sign';
import OtpVerification from './components/OtpVerification';
import Account from './components/account';
import Success from './components/Success';
import Cancel from './components/Cancel';
import PrivacyPolicy from './components/PrivacyPolicy';

function Home() {
  const navigate = useNavigate();  // useNavigate hook for programmatic navigation

  const handleLearnMoreClick = () => {
    navigate('/products', { state: { scrollToWorkSmarter: true } });  // Pass state with navigation
  };

  const handleTryForFreeClick = () => {
    navigate('/pricing');  // Navigate to the Pricing page when "Try for free" is clicked
  };

  return (
    <main className="main-content">
      <div className="imagecontainer">
        <img src={Whitematter} className="Logomain" alt="Whitematter Logo" />
      </div>
      <div className="content-container">
        {/* Left Content */}
        <div className="left-content">
          <h1 className="gradient-text">Monitor Stress, Cognitive Fatigue, Productivity and more...</h1>
          <p>
            Whitematter helps users to optimize <strong>productivity</strong> and maintain<strong> mental well-being</strong> <em>— no wearables needed.</em> With its <strong>AI-driven</strong> neurofeedback system, Whitematter facilitates the development of <strong>Self-Directed Neuroplasticity</strong> by integrating<strong> occupational health guidelines</strong> into its feedback system. We empower employers with <strong>data-driven insights</strong> to balance team workloads and <strong>enhance performance</strong> within their organization.
          </p>
          <div className="buttons">
            <button className="blue-button1" onClick={handleLearnMoreClick}>Learn More</button>  {/* Navigate to Products page */}
            <button className="blue-button" onClick={handleTryForFreeClick}>Try for free</button>  {/* Navigate to Pricing page */}
          </div>
        </div>

        {/* Right Image */}
        <div className="right-content">
          <img src={Whitematterimage} className="side-image" alt="Related Graphic" />
        </div>
      </div>
    </main>
  );
}

// Move Header/Footer conditional logic into Layout component
function Layout({ children }) {
  const location = useLocation();  // useLocation inside Router context

  // Paths where you don't want the Header and Footer
  const hideHeaderFooterRoutes = ['/signup', '/signin','/otp'];

  return (
    <>
      {/* Conditionally render Header */}
      {!hideHeaderFooterRoutes.includes(location.pathname) && <Header />}
      
      {/* Render the main content */}
      {children}

      {/* Conditionally render Footer */}
      {!hideHeaderFooterRoutes.includes(location.pathname) && <Footer />}
    </>
  );
}


function App() {
  return (
    <Router>
      <div className="App">
        <Layout>
          <Routes>
            <Route 
              path="/" 
              element={
                <>
                  <Home />  {/* Home component with useNavigate logic */}
                  <VideoSection />
                  <FeatureSection />
                  <FeatureFamily />
                </>
              } 
            />
            
            {/* Route for the Products page */}
            <Route path="/products" element={<Products />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signin" element={<Sign />} />
            <Route path="/otp" element={<OtpVerification/>} />
            <Route path="/account" element={<Account/>} />
            <Route path="/success" element={<Success/>} />
            <Route path="/cancel" element={<Cancel/>} />
            <Route path="/privacy" element={<PrivacyPolicy/>} />
          </Routes>
        </Layout>
      </div>
    </Router>
  );
}

export default App;
