import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy for Anonymised Biometric Data Collection for Analysis Purposes</h1>

      <section>
        <h2>1. Introduction</h2>
        <p>
          This Privacy Policy outlines the practices of Erasys Ltd. ("Company", "we", "us", or "our")
          regarding the collection, use, and sharing of anonymised biometric data ("Anonymised Biometric
          Data") for analysis purposes. By using our services and providing your biometric data, you
          consent to the collection, anonymisation, and use of your data as described in this policy.
        </p>
      </section>

      <section>
        <h2>2. Collection of Biometric Data</h2>
        <p>
          We collect biometric data that may include, but is not limited to, typing patterns, mouse usage, and
          general patterns of desktop usage. We do not collect facial recognition scans, fingerprints, or iris
          data.
        </p>
      </section>

      <section>
        <h2>3. Anonymisation of Biometric Data</h2>
        <p>
          Upon collection, your biometric data will be subjected to anonymisation techniques to remove or
          obfuscate any information that could personally identify you. The anonymisation process is designed to
          ensure that the data cannot be linked back to you or any other individual.
        </p>
      </section>

      <section>
        <h2>4. Purpose of Data Analysis</h2>
        <p>
          The anonymised biometric data will be used for analysis purposes, which may include improving our
          services, developing new products, conducting research, and enhancing user experiences. The analysis
          aims to understand patterns and trends without compromising individual privacy.
        </p>
      </section>

      <section>
        <h2>5. Data Protection and Security</h2>
        <p>
          We are committed to protecting the security of your biometric data. We implement reasonable security
          measures such as encryption, access controls, and regular security assessments to safeguard your data
          against unauthorised access, disclosure, alteration, or destruction.
        </p>
      </section>

      <section>
        <h2>6. Sharing of Anonymised Data</h2>
        <p>
          Anonymised biometric data may be shared with third parties for research or statistical analysis. We
          ensure that these third parties are bound by confidentiality agreements and do not attempt to re-identify
          the data.
        </p>
      </section>

      <section>
        <h2>7. Compliance with Regulations</h2>
        <p>
          We comply with all applicable laws and regulations regarding the collection, use, and protection of
          biometric data, including but not limited to the General Data Protection Regulation (GDPR), the
          California Consumer Privacy Act (CCPA), and the Biometric Information Privacy Act (BIPA).
        </p>
      </section>

      <section>
        <h2>8. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the
          new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any
          changes.
        </p>
      </section>

      <section>
        <h2>9. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us at{' '}
          <a  className='privacyp' href="mailto:support@erasys.co.uk">support@erasys.co.uk</a>.
        </p>
      </section>

      <p className="final-note">
        By using our services, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
