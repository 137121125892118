// Cancel.js

import React from 'react';

const Cancel = () => {
  return (
    <div>
      <h2>Payment was canceled.</h2>
      <p>Your payment was canceled. You can try again or contact support.</p>
    </div>
  );
};

export default Cancel;
